/* eslint-disable complexity */
// React imports
import React from 'react';
import { useEffect, useState } from 'react';
import '../App.css';

// Firestore
// import db from '../firebase';
// import { collection, query, where, getDocs } from 'firebase/firestore';

// Import styling
import styled from '@emotion/styled';

// Import components
import SimpleMap from './mapComponent';
import Header from '../components/header.js';
import { ShopTimes } from '../components/ShopTimes';
import Website from './buttons/Website';

// Import state tool
// import { GlobalContext } from '../context/GlobalState';
// import { giveOpenTime } from '../Functions/openTime';
import { useMediaQuery } from 'react-responsive';
import { projectFirestore } from '../Firebase/config';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import db from '../firebase';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Image = styled.img`
  box-sizing: border-box;
  height: 100%;
  width: auto;
  object-fit: contain;
  overflow: hidden;
`;

// For shop frame
const TopShopFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  background: white;
`;

const ShopFrame = styled.div`
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  width: 100vw;
`;

const LeftBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 50%;
  height: 100%;
`;

const RightBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  height: 90%;
  margin: 12px;
`;

const ShopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImageFrameShop = styled.div`
  box-sizing: border-box;
  float: left;
  /* background: red; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  max-height: 90%;
`;

const ImageFrameShopMobile = styled.div`
  box-sizing: border-box;
  float: left;
  /* background: red; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 90%;
`;

const InformationFrameShop = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* background: blue; */
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

const ShopName = styled.div`
  font-weight: bold;
`;

const ShopDescription = styled.div`
  flex: 1;
  border-bottom: 1px solid #e9eaed;
  border-top: 1px solid #e9eaed;
  padding: 5px;
`;

const ShopAddress = styled.div`
  border-bottom: 1px solid #e9eaed;
  padding: 5px;
`;

const ShopPhoneNumber = styled.div`
  border-bottom: 1px solid #e9eaed;
  padding: 5px;
`;

const ShopLogo = styled.div`
  box-sizing: border-box;
  float: left;
  width: 50px;
  height: 50px;
  /* background: red; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

// const TimeRow = styled.div`
//   display: flex;
//   flex-direction: row;
// `;

// const Day = styled.div`
//   width: 100px;
// `;

function ShopDetail(props) {
  // use case 1 - id is taken from url on shop site
  const { id } = useParams();
  // use case 2 - id is passed down in props

  // Define states
  const [shop, setShop] = useState(null);

  const [, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const isDesktop = useMediaQuery({ query: '(min-width: 1171px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const [shopId, setShopId] = useState(props.shop);

  function phoneIsNaN(num) {
    if (typeof num === 'number') {
      return isNaN(num);
    } else if (typeof num === 'string') {
      if (num.length > 6) {
        return true;
      }
    }
    return false;
  }
  // Retrieve Shop Document
  useEffect(() => {
    // Differentiate between use cases

    if (props.type === 'detail') {
      setShopId(props.shop);
    } else {
      setShopId(id);
    }

    const handleQuery = async shopId => {
      try {
        const collectionRef = collection(db, 'shops');
        const q = query(collectionRef, where('__name__', '==', shopId));
        const snapshot = await getDocs(q);
        const results = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        setShop(results[0]);
        setLoading(false);
      } catch (err) {
        console.log(err);

        setError('Could not find shop');
      }
    };

    try {
      handleQuery(shopId);
    } catch (err) {
      console.log(err);
    }
  }, [shopId]);

  return (
    <>
      <Container>
        {props.header && <Header />}
        <TopShopFrame>
          {/* {error && <p className="error">{error}</p>} */}
          {loading && <p className="loading">Loading...</p>}
          {shop && (
            <ShopFrame>
              {/* For Desktop */}

              {isDesktop && (
                <LeftBar>
                  <InformationFrameShop>
                    <ShopHeader>
                      <ShopLogo>
                        <Image src={!loading && shop.shop_logo} />
                      </ShopLogo>
                      <ShopName>{!loading && shop.shop_name}</ShopName>
                    </ShopHeader>
                    {/* <div>{status}</div> */}
                    <ShopDescription>
                      {!loading && shop.shop_description}
                    </ShopDescription>
                    <ShopAddress>{!loading && shop.shop_location}</ShopAddress>
                    {!loading && !phoneIsNaN(shop.phone) && (
                      <ShopPhoneNumber>
                        <img
                          className="phone-icon"
                          src="https://img.icons8.com/ios/50/000000/apple-phone.png"
                        />
                        +{shop.phone}
                      </ShopPhoneNumber>
                    )}{' '}
                    <ShopTimes times={shop} />
                    <div className="website-container-desktop">
                      <Website url={shop.website} />
                    </div>
                  </InformationFrameShop>
                </LeftBar>
              )}
              {isDesktop && (
                <RightBar>
                  <ImageFrameShop>
                    <Image src={!loading && shop.shop_image_one} />
                  </ImageFrameShop>
                </RightBar>
              )}

              {/* For Mobile */}

              {isMobile && (
                <InformationFrameShop>
                  <ShopHeader>
                    <ShopLogo>
                      <Image src={!loading && shop.shop_logo} />
                    </ShopLogo>
                    <ShopName>{!loading && shop.shop_name}</ShopName>
                  </ShopHeader>
                  {/* <div>{status}</div> */}
                  <ImageFrameShopMobile>
                    <Image src={!loading && shop.shop_image_one} />
                  </ImageFrameShopMobile>
                  <ShopDescription>
                    {!loading && shop.shop_description}
                  </ShopDescription>
                  <ShopAddress>{!loading && shop.shop_location}</ShopAddress>
                  {!loading && !phoneIsNaN(shop.phone) && (
                    <ShopPhoneNumber>
                      <img
                        className="phone-icon"
                        src="https://img.icons8.com/ios/50/000000/apple-phone.png"
                      />
                      +{shop.phone}
                    </ShopPhoneNumber>
                  )}
                  <ShopTimes times={shop} />
                  <div className="website-container">
                    <Website url={shop.website} />
                  </div>
                </InformationFrameShop>
              )}
            </ShopFrame>
          )}
          {shop && <SimpleMap shop={shop} />}
        </TopShopFrame>
      </Container>
    </>
  );
}

export default ShopDetail;
