import React from 'react';
// Import styling
import styled from '@emotion/styled';
import '../App.css';
import { isStoreOpen } from './ShopTimesHelper';
import Reserve from './buttons/Reserve';
import ToShop from './buttons/ToShop';

const TimeRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1px;
`;

const Day = styled.div`
  width: 100px;
`;

export function ShopTimes(props) {
  // const mon_o = Date(props.times.mon_o).toLocaleTimeString();
  // console.log(mon_o);
  const isOpen = isStoreOpen(props.times);

  return (
    <div className="times-container">
      {isOpen && (
        <p>
          <b>
            {' '}
            <b style={{ color: '#32a84a' }}>Currently Open</b>
          </b>
        </p>
      )}
      {!isOpen && (
        <p>
          <b>
            {' '}
            <b style={{ color: '#d6546b' }}>Currently Closed</b>
          </b>
        </p>
      )}
      {isOpen && (
        <div>
          {`${props.times.shop_name} `}
          is open right now.
          <br />
          Use the navigation button to go there and get your item.
          <br />
          <ToShop lat={props.times.lat} lng={props.times.lng} />
        </div>
      )}
      {!isOpen && (
        <div>
          {' '}
          {`${props.times.shop_name} `}
          is closed right now.
          <br />
          Use the reserve button to secure this item.
          <br />
          <Reserve />
        </div>
      )}
      <TimeRow>
        <Day>Monday:</Day>
        <div>
          {' '}
          {props.times.mon_o}-{props.times.mon_c}
        </div>
      </TimeRow>
      <TimeRow>
        <Day>Tuesday:</Day>
        <div>
          {' '}
          {props.times.tue_o}-{props.times.tue_c}
        </div>
      </TimeRow>
      <TimeRow>
        <Day>Wednesday:</Day>
        <div>
          {' '}
          {props.times.wed_o}-{props.times.wed_c}
        </div>
      </TimeRow>
      <TimeRow>
        <Day>Thursday:</Day>
        <div>
          {' '}
          {props.times.thu_o}-{props.times.thu_c}
        </div>
      </TimeRow>
      <TimeRow>
        <Day>Friday:</Day>
        <div>
          {' '}
          {props.times.fri_o}-{props.times.fri_c}
        </div>
      </TimeRow>
      <TimeRow>
        <Day>Saturday:</Day>
        <div>
          {' '}
          {props.times.sat_o}-{props.times.sat_c}
        </div>
      </TimeRow>
      <TimeRow>
        <Day>Sunday:</Day>
        <div>
          {' '}
          {props.times.sun_o}-{props.times.sun_c}
        </div>
      </TimeRow>
    </div>
  );
}
