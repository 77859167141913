import React from 'react';
import ReservedTile from '../productTile/ReservedTile';
import { useFirestore } from '../../hooks/useFirestore';

// style
import styles from './UserProfile.module.css';
import './ProductList.css';

export default function ReservedList({ products }) {
  const { deleteDocument, response } = useFirestore('reserved_products');
  console.log('Response', response);
  console.log('Reserved List');
  return (
    <ul>
      {products.map(product => (
        <li key={product.id} className="list-item">
          <ReservedTile product={product} deleteDocument={deleteDocument} />
        </li>
      ))}
    </ul>
  );
}
