
import React, { useContext, useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { GlobalContext } from '../context/GlobalState';
import { API_KEY } from '../API_KEY';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

const SimpleMap = ({ lat, lng, shop }) => {
  const context = useContext(GlobalContext);
  const zoom = 11;

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  useEffect(() => {
    // Case 1 - information comes from context
    if (context.product.lat) {
      setLatitude(context.product.lat);
      setLongitude(context.product.lng);
    } else {
      // Case 2 - Information is passed down from props
      setLatitude(shop.lat);
      setLongitude(shop.lng);
    }
  }, [shop]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '400px', width: '100%', padding: '0px' }}>
      <GoogleMapReact
        // bootstrapURLKeys={{ key: process.env.REACT_APP_GM_KEY }}
        bootstrapURLKeys={{ key: API_KEY }}

        defaultCenter={{
          lat: latitude,
          lng: longitude,
        }}
        defaultZoom={zoom}
      >
        <LocationOnIcon
          color="secondary"
          lat={latitude}
          lng={longitude}
          text="Shop XY"
        />
      </GoogleMapReact>
    </div>
  );
  // }
};

export default SimpleMap;
