import React, { useState, useEffect } from 'react';
import '../../App.css';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';

import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useFirestore } from '../../hooks/useFirestore';

// Hooks
import { useAuthContext } from '../../hooks/useAuthContext';

export default function Reserve({
  id,
  name,
  price,
  producer,
  description,
  image,
  shop,
  lat,
  lng,
  shopName,
  inStock,
  productType,
  productUrl,
}) {
  // For favorite/ reserve functionality
  const [pending, setPending] = useState(false);
  const [reserved, setReserved] = useState(false);
  const { user } = useAuthContext();
  const { addDocument, response } = useFirestore('reserved_products');

  const handleReserve = e => {
    // Analytics
    const analytics = getAnalytics();
    logEvent(analytics, 'product_button_reserve');
    e.preventDefault();

    // Get user ID
    const uid = user.uid;
    const username = user.displayName;

    // Add document
    addDocument({
      username,
      uid,
      id,
      name,
      price,
      producer,
      description,
      image,
      shop,
      lat,
      lng,
      shopName,
      inStock,
      productType,
      productUrl,
      state: 'Pending',
    });

    // Print to check
    console.log(
      'user',
      user.uid,
      id,
      name,
      price,
      producer,
      description,
      image,
      shop,
      lat,
      lng,
      shopName,
      inStock,
      productType,
      productUrl
    );

    // Change icon state
    setReserved(true);
  };

  const handleUnReserve = e => {
    const analytics = getAnalytics();
    logEvent(analytics, 'product_button_unreserve');
    e.preventDefault();

    // Change icon state
    setReserved(false);
  };

  // Only chnage state when document creation is successful
  useEffect(() => {
    if (response.success) {
      // Change Icon state
      setReserved(true);
    }
  }, [response.success]);

  return (
    <>
      {/* TODO: implement Pending State */}

      {!reserved && user && (
        <Button
          className="reserve-button-false"
          size="small"
          onClick={handleReserve}
        >
          <ShoppingCartIcon className="cart-icon" />
          <p>Reserve</p>
        </Button>
      )}
      {reserved && user && (
        <Button
          className="reserve-button-true"
          size="small"
          onClick={handleUnReserve}
        >
          {/* <CheckIcon className="cart-icon" /> */}
          <p>Pending</p>
        </Button>
      )}
      {!reserved && !user && (
        <Link to="/signup" style={{ textDecoration: 'none' }}>
          <Button
            className="reserve-button-false"
            size="small"
            style={{ textDecoration: 'none' }}
          >
            <ShoppingCartIcon className="cart-icon" />
            <p>Reserve</p>
          </Button>
        </Link>
      )}
    </>
  );
}
