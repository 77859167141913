import React from 'react';
import ProductTile from '../productTile/ProductTile';
import { useFirestore } from '../../hooks/useFirestore';

// style
import styles from './UserProfile.module.css';
import './ProductList.css';

export default function ProductList({ products }) {
  const { deleteDocument, response } = useFirestore('saved_products');
  // console.log('Response', response);
  return (
    <ul>
      {products.map(product => (
        <li key={product.id} className="list-item">
          <ProductTile product={product} deleteDocument={deleteDocument} />
        </li>
      ))}
    </ul>
  );
}
