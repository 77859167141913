import React from 'react';
import '../App.css';
import distanceCalculator from '../Functions/distanceCalculator';
import { GlobalContext } from '../context/GlobalState';
import { useContext, useEffect, useRef, useState } from 'react';

// Source: https://www.geodatasource.com/developers/javascript

export default function distance(props) {
  // For product information from updated context
  const context = useContext(GlobalContext);

  // Location variables for user
  const latUser = context.lat;
  const lngUser = context.lng;

  // Location variables for Shop/Product
  const latShop = props.productLat;
  const lngShop = props.productLng;

  // console.log(latUser, lngUser, latShop, lngShop);

  const dist = distanceCalculator(latUser, lngUser, latShop, lngShop, 'M');
  // console.log('distance', dist);
  return <div className="direction-number">{dist.toFixed(1)}mi</div>;
}

//Alternative with useEffect

// export default function distance(props) {
//   // For product information from updated context
//   const _context = useContext(GlobalContext);
//   const [distance, setDistance] = useState(1);

//   const context = useRef(_context).current;
//   console.log('----> Context in DistanceApp', context);

//   useEffect(() => {
//     // Location variables for user
//     const latUser = context.lat;
//     const lngUser = context.lng;

//     // Location variables for Shop/Product
//     const latShop = props.productLat;
//     const lngShop = props.productLng;

//     console.log(latUser, lngUser, latShop, lngShop);

//     const dist = distanceCalculator(latUser, lngUser, latShop, lngShop, 'M');
//     setDistance(dist);
//   }, [_context]);
//   return <div className="direction-number">{distance.toFixed(1)}mi</div>;
// }
