// course
import { projectFirestore } from '../../Firebase/config';
import React, { useState, useEffect } from 'react';
import '../ShopsPage.css';
import Footer from '../footer/Footer';

// import components
import ShopsList from './ShopsList';
import Header from '../header.js';

export default function ShopsPage() {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsPending(true);

    projectFirestore
      .collection('shops')
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          setError('No shops to load');
          setIsPending(false);
        } else {
          let results = [];
          snapshot.docs.forEach(doc => {
            results.push({ id: doc.id, ...doc.data() });
          });
          console.log(results);
          setData(results);
          setIsPending(false);
        }
      })
      .catch(err => {
        setError(err.message);
        setIsPending(false);
      });
  }, []);

  return (
    <>
      <div className="shops-home">
        <Header />
        <h1>The Shops</h1>
        {error && <p className="error">{error}</p>}
        {isPending && <p className="loading">Loading...</p>}
        {data && <ShopsList shops={data} />}
      </div>
      <Footer />
    </>
  );
}
