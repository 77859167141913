import React from 'react';
import './ShopsList.css';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// import components
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import ShopDistance from '../ShopDistance';

export default function ShopsList({ shops }) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1171px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 1170px)' });

  return (
    <div className="list-container">
      <div className="shops-list">
        {shops.map(shop => (
          <Link
            key={shop.id}
            to={'/shops/' + shop.id}
            style={{ textDecoration: 'none' }}
          >
            {isDesktop && (
              <div className="shop-card">
                <div className="shop-image">
                  <img src={shop.shop_image_one} alt="Shop" />
                </div>
                <div className="info-container">
                  <div className="first-row">
                    <div className="shop-name-list">{shop.shop_name}</div>
                    <div className="directions">
                      <DirectionsWalkIcon className="direction-icon" />
                      {/* <div className="direction-number">300m</div> */}
                      <ShopDistance
                        productLat={shop.lat}
                        productLng={shop.lng}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isMobile && (
              <div className="shop-card-mobile">
                <div className="shop-image-mobile">
                  <img src={shop.shop_image_one} alt="Shop" />
                </div>
                <div className="info-container">
                  <div className="first-row-mobile">
                    <div className="shop-name">{shop.shop_name}</div>
                    <div className="directions">
                      <DirectionsWalkIcon className="direction-icon" />
                      {/* <div className="direction-number">300m</div> */}
                      <ShopDistance
                        productLat={shop.lat}
                        productLng={shop.lng}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
}
