import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import Button from '@mui/material/Button';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

// Hooks
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';

function Sidebar({ getLocation, status }) {
  const [sidebar, setSidebar] = useState(false);
  const { user } = useAuthContext();
  const { logout } = useLogout();

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <Link to="#" className="menu-bars">
        <MenuIcon fontSize="large" onClick={showSidebar} />
      </Link>

      <div className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className="nav-menu-items" onClick={showSidebar}>
          <li className="navbar-toggle">
            <Link to="#">
              <CloseIcon />
            </Link>
          </li>
          <li className="nav-list-item" onClick={getLocation}>
            <Button variant="contained">Use Current Location</Button>
          </li>
          {!user && (
            <li className="nav-list-item">
              <Link to={'/login'} style={{ textDecoration: 'none' }}>
                Login
              </Link>
            </li>
          )}
          {!user && (
            <li className="nav-list-item">
              <Link to={'/signup'} style={{ textDecoration: 'none' }}>
                Sign up
              </Link>
            </li>
          )}
          {user && (
            <>
              <li className="nav-list-item-name">{user.displayName}</li>
              <li className="nav-list-item">
                <Link
                  // to={'/' + user.displayName}
                  to={'/favorites'}
                  style={{ textDecoration: 'none' }}
                >
                  My Favorites
                </Link>
              </li>
              <li className="nav-list-item">
                <Link
                  // to={'/' + user.displayName}
                  to={'/reserve'}
                  style={{ textDecoration: 'none' }}
                >
                  Reserved Items
                </Link>
              </li>
            </>
          )}
          {user && (
            <li className="nav-list-item" onClick={logout}>
              Logout
            </li>
          )}
          <li className="nav-list-item">
            <Link to={'/shops'} style={{ textDecoration: 'none' }}>
              Shops
            </Link>
          </li>
          <li className="nav-list-item">
            <Link to={'/about'} style={{ textDecoration: 'none' }}>
              About
            </Link>
          </li>
          <li className="nav-list-item">
            <a
              style={{ textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeOA26a2wCxOFeVnfLnHpq5UedXxaQVbS2ueemZ3jTQhoMTjQ/viewform?usp=sf_link"
            >
              Give Feedback
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
