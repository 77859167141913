import React from 'react';
import styles from './UserProfile.module.css';
import Header from '../header';
import ReservedList from './ReservedList';
import { useAuthContext } from '../../hooks/useAuthContext';

import { useCollection } from '../../hooks/useCollection';

export default function Reserved() {
  const { user } = useAuthContext();
  const { documents, error } = useCollection(
    'reserved_products',
    ['uid', '==', user.uid],
    ['createdAt', 'desc']
  );

  console.log('>>>>>>>>', documents);

  return (
    <div>
      <Header />
      <h2>My Reserved Items</h2>
      {error && <p>{error}</p>}
      {documents && (
        <div className={styles.container}>
          <div className={styles.favorites}>
            <ReservedList products={documents} />
          </div>
        </div>
      )}
    </div>
  );
}
