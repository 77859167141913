import React from 'react';
import InventoryIcon from '@mui/icons-material/Inventory';
import Button from '@mui/material/Button';

export function InStock() {
  return (
    <Button className="in-stock-button">
      <InventoryIcon className="cart-icon" />
      In Stock
    </Button>
  );
}

export function NotInStock() {
  return (
    <Button className="not-in-stock-button">
      <InventoryIcon className="cart-icon" />
      <del>In Stock</del>
    </Button>
  );
}
