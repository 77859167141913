import React, { useEffect, useState, useContext } from 'react';
import ShopDetail from '../ShopDetail.js';
import Header from '../header.js';
import db from '../../firebase';
import {
  collection,
  query,
  where,
  doc,
  getDocs,
  increment,
  updateDoc,
} from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { numberFormat } from '../../Functions/numberFormatting';
// import { handleQuery } from './Firebase/queries';
import { GlobalContext } from '../../context/GlobalState';
import { giveOpenTime } from '../../Functions/openTime';
import { useMediaQuery } from 'react-responsive';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import Metatags from '../Metatags';

// Icon imports
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import InfoIcon from '@mui/icons-material/Info';
import InventoryIcon from '@mui/icons-material/Inventory';
import Button from '@mui/material/Button';
import { InStock, NotInStock } from '../buttons/Stock';
import ToShop from '../buttons/ToShop';

import './ProductDetail.css';

// Import modules
import Reserve from '../buttons/Reserve';
import Save from '../buttons/Save';
import Share from '../buttons/Share';

// Component function for Product Detail
const ProductDetail = props => {
  // For shop database request
  const [shop, setShop] = useState();
  // For loading state in shop
  const [loading, setLoading] = useState(true);
  // For loading state in product
  const [loadingProduct, setLoadingProduct] = useState(true);
  // For Responsive Design
  const isDesktop = useMediaQuery({ query: '(min-width: 1171px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  //Special state case for description
  const [description, setDescription] = useState('Default Description');

  // For product information from updated context
  const context = useContext(GlobalContext);
  // Initial state for product if props are passed
  const [product, setProduct] = useState(context.product);
  const [shopId, setShopId] = useState();

  // Get id from url
  let { id } = useParams();

  // update increment "reads" by 1 in firestore
  try {
    const productRef = context.product.id;
    const docRef = doc(db, 'products', productRef);
    updateDoc(docRef, {
      reads: increment(1),
    });
  } catch (err) {
    console.log(err);
  }

  // Get shop information with firestore db request
  useEffect(() => {
    // Get Product data
    const handleQueryProduct = async productId => {
      try {
        const collectionRef = collection(db, 'products');
        const q = query(collectionRef, where('__name__', '==', productId));
        const snapshot = await getDocs(q);
        const results = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));

        setProduct(await results[0]);
        setProduct(
          await {
            update: 'yes',
            id: results[0].id,
            name: results[0].product_name,
            price: results[0].product_price,
            producer: results[0].brand,
            description: results[0].product_description,
            image: results[0].product_image,
            shop: results[0].shop_reference,
            lat: results[0]._geoloc._lat,
            lng: results[0]._geoloc._long,
            shopName: results[0].shop_name,
            inStock: results[0].in_stock_boolean,
            productType: results[0].product_type,
            productUrl: results[0].url,
          }
        );
        setLoadingProduct(false);
        setDescription(results[0].product_description);
        setShopId(results[0].shop_reference);
      } catch (err) {
        console.log(err);
      }
    };

    // Get Shop data
    const handleQuery = async shopId => {
      try {
        // const shopId = 'V6W4TvYDBjFKS50saJQr';
        const collectionRef = collection(db, 'shops');
        const q = query(collectionRef, where('__name__', '==', shopId));

        const snapshot = await getDocs(q);
        const results = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        setShop(await results[0]);
        setLoading(false);
        // setDescription(context.product.description);
      } catch (err) {
        console.log(err);
      }
    };

    // Try product
    try {
      if (product.id === 'XXXXX') {
        handleQueryProduct(id);
      } else {
        handleQueryProduct(product.id);
      }
    } catch (err) {
      console.log(err);
    }

    // Try shop
    try {
      // if (shopId) {

      handleQuery(shopId.replace('shops/', ''));
      // }
    } catch (err) {
      console.log(err);
    }
  }, [shopId]);

  // Function to navigate to Shop with Google Maps
  const goToGm = () => {
    const analytics = getAnalytics();
    logEvent(analytics, 'maps_navigation_clicked');
    window.open(
      `https://maps.google.com?q=${context.product.lat},${context.product.lng}`
    );
  };

  return (
    <div className="container">
      <Metatags
        title={product.name}
        image={product.image}
        url={product.productUrl}
        shop={product.shopName}
      />
      <Header />
      {isDesktop && (
        <div className="product-frame">
          <div className="image-frame">
            <img className="image" src={product.image} alt="product" />
          </div>
          <div className="information-frame">
            <div className="product-name">{product.name}</div>
            <div className="product-producer">by {product.producer}</div>
            <div className="price-container">
              <div className="product-price">
                {numberFormat(product.price / 100)}
              </div>
              {product.inStock && <InStock />}
              {!product.inStock && <NotInStock />}
            </div>
            <div className="product-description">
              {parse(description)}{' '}
              <a href={product.productUrl} target="_blank">
                Source
              </a>
            </div>
            <Reserve />
            <Save
              id={product.id}
              name={product.name}
              price={product.price}
              producer={product.producer}
              description={product.description}
              image={product.image}
              shop={product.shop}
              lat={product.lat}
              lng={product.lng}
              shopName={product.shopName}
              inStock={product.inStock}
              productType={product.productType}
              productUrl={product.productUrl}
            />
            <ToShop lat={product.lat} lng={product.lat} />
          </div>
        </div>
      )}
      {isMobile && (
        <div className="product-frame-mobile">
          <div className="image-frame-mobile">
            <img className="image" src={product.image} alt="product" />
          </div>
          <div className="information-frame">
            <div className="product-name">{product.name}</div>
            <div className="product-producer">by {product.producer}</div>
            <div className="price-container">
              <div className="product-price">
                {numberFormat(product.price / 100)}
              </div>
              {product.inStock && <InStock />}
              {!product.inStock && <NotInStock />}
            </div>
            <div className="button-container">
              <Reserve />
              <Save
                id={product.id}
                name={product.name}
                price={product.price}
                producer={product.producer}
                description={product.description}
                image={product.image}
                shop={product.shop}
                lat={product.lat}
                lng={product.lng}
                shopName={product.shopName}
                inStock={product.inStock}
                productType={product.productType}
                productUrl={product.productUrl}
              />
              <Share
                product={product.name}
                shop={product.shopName}
                image={product.image}
              />
            </div>
            <div className="product-description">
              {parse(description)}{' '}
              <a href={product.productUrl} target="_blank">
                Source
              </a>
            </div>
            {/* <div className="product-description">
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div> */}

            <ToShop lat={product.lat} lng={product.lng} />
          </div>
        </div>
      )}
      {!loading && (
        <ShopDetail type="detail" shop={product.shop.replace('shops/', '')} />
      )}
    </div>
  );
};

export default ProductDetail;
