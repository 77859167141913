import React from 'react';
import Header from '../header';
import './About.css';
import Button from '@mui/material/Button';

export default function About() {
  return (
    <>
      <Header />
      <div className="about-container">
        <h2>About</h2>
        <br />
        <br />
        Plaza is a search engine that allows you to search and reserve products
        from local retailers around you.
        <br />
        <br />
        <b>For Shop Owners</b>
        <br />
        Connect your POS system with your product range to our search engine
        with no work. We only need your consent to get view access to your
        inventory and integrate your product assortment into our search engine.
        Plaza users can then search for your products in the web app and come to
        your physical shop to buy them. Currently, we only offer search
        functionality with our application - all other processes such as
        payments will not be touched by us. This means you won’t have to give up
        any revenue to promote your products with Plaza.
        <br />
        <br />
        <b>Get in touch</b>
        Get in touch at fabian@tryplaza.io.
        <br />
        <a
          className="astext"
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/forms/d/e/1FAIpQLScDre9LvDgQQOL-Bix7xOi3rTmBKigSoUpsiDmUEnExO7_R4A/viewform?usp=sf_link"
        >
          <Button variant="contained">Request Shop Access</Button>
        </a>
      </div>
    </>
  );
}
