import React from 'react';
import { useState, useEffect } from 'react';
import { projectAuth } from '../Firebase/config';
import { useAuthContext } from './useAuthContext';

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();
  const [isCancelled, setIsCancelled] = useState(false);

  // Attention: Only specific properties!!
  const signup = async (email, password, displayName) => {
    setError(null);
    setIsPending(true);

    try {
      // sign up user
      const res = await projectAuth.createUserWithEmailAndPassword(
        email,
        password
      );

      if (!res) {
        throw new Error('Could not complete signup');
      }

      // Add display name to user
      await res.user.updateProfile({ displayName });

      // Send email verification
      await res.user.sendEmailVerification();

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user });

      // update state
      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        console.log(err.message);
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  // Cleanup function
  // After the component unmounts - don't allow state to be updated
  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { error, isPending, signup };
};
