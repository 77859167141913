import React from 'react';
import { Helmet } from 'react-helmet';

export default function Metatags({ title, image, url, shop }) {
  return (
    <div>
      <Helmet>
        {/* Primary Meta Tags */}
        <meta charSet="utf-8" />
        <title>Plaza</title>
        <meta name="title" content={title} />
        <meta
          name="description"
          content={
            'Check out ' + { title } + ' from ' + { shop } + ' on Plaza. '
          }
        />
        {/* Open Graph / Facebook */}
        <meta property="og:title" content={title} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={document.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Plaza" />
        <meta
          property="og:description"
          content={
            'Check out ' + { title } + ' from ' + { shop } + ' on Plaza. '
          }
        />

        {/* Twitter */}
        <meta property="twitter:card" content={image} />
        <meta property="twitter:url" content={document.location.href} />
        <meta property="twitter:title" content={title} />
        <meta
          property="twitter:description"
          content={
            'Check out ' + { title } + ' from ' + { shop } + ' on Plaza. '
          }
        />
        <meta property="twitter:image" content={image} />
      </Helmet>
    </div>
  );
}
