import CookieConsent from 'react-cookie-consent';

import React from 'react';

export default function CookieConsentComponent() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All"
      style={{ background: '#F25701', fontSize: '10px' }}
      buttonStyle={{ color: '#4e503b', fontSize: '10px' }}
      expires={150}
    >
      We use cookies that are essential for our site to work.  To improve our
      website, we use additional cookies to help us understand how visitors use
      the site, measure traffic to our site from social media platforms and to
      personalise your experience.  Some of the cookies that we use are provided
      by third parties.  Please visit our{' '}
      <a href="https://www.iubenda.com/privacy-policy/85262492">
        Privacy Policy
      </a>{' '}
      and{' '}
      <a href="https://www.iubenda.com/privacy-policy/85262492/cookie-policy">
        {' '}
        Cookie Policy
      </a>{' '}
      for more information. To accept all cookies click 'Accept all'.{' '}
      <span style={{ fontSize: '10px' }}></span>
    </CookieConsent>
  );
}
