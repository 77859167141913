import React from 'react';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import Button from '@mui/material/Button';
import '../../App.css';

export default function Website({ url }) {
  // Function to navigate to Shop with Google Maps
  const goToWebsite = () => {
    window.open(url);
  };
  return (
    <Button className="website-button" size="small" onClick={goToWebsite}>
      <WebAssetIcon className="cart-icon" />
      Website
    </Button>
  );
}
