import React from 'react';
import styles from './Login.module.css';
import { useState } from 'react';
import Header from '../header.js';
import { useLogin } from '../../hooks/useLogin';
import './Login.css';
import Footer from '../footer/Footer';
import Button from '@mui/material/Button';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, error, isPending } = useLogin();

  const handleSubmit = e => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <>
      <Header />
      <div className="login-container">
        <form
          id="form2"
          onSubmit={handleSubmit}
          className={styles['login-form']}
        >
          <h2>Login</h2>
          <label>
            <span>E-mail:</span>
            <input
              type="email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </label>
          <label>
            <span>Password:</span>
            <input
              type="password"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
          </label>
          {!isPending && (
            <Button
              form="form2"
              type="submit"
              variant="contained"
              className="btn"
            >
              Login
            </Button>
          )}
          {isPending && (
            <Button className="btn" disabled>
              loading
            </Button>
          )}
          {error && <p>{error}</p>}
        </form>
      </div>
      <Footer />
    </>
  );
}
