import React from 'react';
import { Link } from 'react-router-dom';
// Import styles
import '../../App.css';
import { styled } from '@mui/material/styles';

// import components
import StoreIcon from '@mui/icons-material/Store';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import ShopDistance from '../ShopDistance';
import Button from '@mui/material/Button';

// Style button
const ShopButton = styled(Button)({
  fontSize: 12,
  textTransform: 'none',
  '&:hover': {
    // backgroundColor: '#0069d9',
    // borderColor: '#0062cc',
    // boxShadow: 'none',
  },
});

export default function ShopButtonNew(props) {
  return (
    <>
      <Link
        to={`/shops/${props.sref.replace('shops/', '')}`}
        style={{ textDecoration: 'none' }}
        shop={props.sref}
      >
        <ShopButton variant="outlined" size="small" className="shop-button">
          <StoreIcon className="shop-icon" />
          <div className="shop-name">{props.name}</div>
          <div className="directions">
            <DirectionsWalkIcon className="direction-icon" />
            <ShopDistance productLat={props.lat} productLng={props.lng} />
          </div>
        </ShopButton>
      </Link>
    </>
  );
}
