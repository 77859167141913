// import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import firestore from 'firebase/compat/firestore';
import 'firebase/compat/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDOy6VPuD8zVT9KR90nM4QUpmxlesKHSlE',
  authDomain: 'plaza-app-1018c.firebaseapp.com',
  projectId: 'plaza-app-1018c',
  storageBucket: 'plaza-app-1018c.appspot.com',
  messagingSenderId: '321102383283',
  appId: '1:321102383283:web:394364a3da1959cd5d88c6',
  measurementId: 'G-093CLTSTCH',
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default getFirestore();
