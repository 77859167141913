import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { GlobalProvider } from './context/GlobalState.js';
import { AuthContextProvider } from './context/AuthContext';
import PlazaPreview from './assets/plaza-preview.png';
import { Helmet } from 'react-helmet';

ReactDOM.render(
  <GlobalProvider>
    <AuthContextProvider>
      <Helmet>
        {/* Primary Meta Tags */}
        <meta charSet="utf-8" />
        <title>PLAZA</title>
        <meta name="title" content="PLAZA" />
        <meta
          name="description"
          content="Find what you need online and then buy it from a shop near you."
        />
        {/* Open Graph / Facebook */}
        <meta property="og:title" content="PLAZA" />
        <meta property="og:image" content={PlazaPreview} />
        <meta property="og:url" content="https://tryplaza.io" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="PLAZA" />
        <meta
          property="og:description"
          content="Find what you need online and then buy it from a shop near you."
        />

        {/* Twitter */}
        <meta property="twitter:card" content={PlazaPreview} />
        <meta property="twitter:url" content="https://tryplaza.io/" />
        <meta property="twitter:title" content="PLAZA" />
        <meta
          property="twitter:description"
          content="Find what you need online and then buy it from a shop near you."
        />
        <meta property="twitter:image" content={PlazaPreview} />
      </Helmet>
      <App />
    </AuthContextProvider>
  </GlobalProvider>,
  document.getElementById('root')
);
