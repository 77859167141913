import React from 'react';
import '../../App.css';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="menu-box-footer">
        <a
          href="https://www.iubenda.com/privacy-policy/85262492"
          style={{ textDecoration: 'none' }}
          className="astext"
        >
          Privacy Policy
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/85262492/cookie-policy"
          style={{ textDecoration: 'none' }}
          className="astext"
        >
          Cookie Policy
        </a>
      </div>
    </footer>
  );
}
