import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import '../../App.css';

export default function ReserveTrash({ deleteDocument, productId }) {
  const handleDelete = e => {
    e.preventDefault();
    deleteDocument(productId);
  };

  return (
    <Button className="trash-button" onClick={handleDelete}>
      <DeleteIcon className="reserve-trash-border-icon" />
    </Button>
  );
}
