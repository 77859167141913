export function numberFormat(number) {
  const formattedNumber = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    currencyDisplay: 'symbol',
  }).format(number);
  return formattedNumber;
}

