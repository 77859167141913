import { useState } from 'react';
import React from 'react';
import Header from '../header.js';
import { useSignup } from '../../hooks/useSignup';
import Footer from '../footer/Footer';
import Button from '@mui/material/Button';

// styles
import styles from './Signup.module.css';
import './Signup.css';

export default function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const { signup, isPending, error } = useSignup();
  const [agree, setAgree] = useState(false);

  console.log('in Sign up');

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
  };

  const handleSubmit = e => {
    e.preventDefault();
    signup(email, password, displayName);
  };

  return (
    <>
      <Header />
      <div className="login-container">
        <form
          id="form1"
          onSubmit={handleSubmit}
          className={styles['signup-form']}
        >
          <h2>sign up</h2>
          <p className="register-info">
            Interested in buying a product locally? <br />
            <br />
            Register now to save your favourite products and reserve them
            directly in the physical shop. <br />
            <br />
            We will take care of the reservation for you.{' '}
          </p>
          <label>
            <span>Email:</span>
            <input
              type="email"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </label>
          <label>
            <span>Password:</span>
            <input
              type="password"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
          </label>
          <label>
            <span>Reservation Name:</span>
            <input
              type="text"
              onChange={e => setDisplayName(e.target.value)}
              value={displayName}
            />
          </label>
          <div className="checkbox-container">
            <input
              className="checkbox"
              type="checkbox"
              id="agree"
              onChange={checkboxHandler}
            />
            <label htmlFor="agree">
              {' '}
              I agree to{' '}
              <b>
                <a href="https://www.iubenda.com/privacy-policy/85262492">
                  Terms and Conditions.
                </a>
              </b>
            </label>
          </div>

          {!isPending && (
            <Button
              form="form1"
              type="submit"
              variant="contained"
              disabled={!agree}
            >
              Sign up
            </Button>
          )}
          {isPending && (
            <Button className="btn" disabled>
              loading
            </Button>
          )}
          {error && <p>{error}</p>}
        </form>
      </div>
      <Footer />
    </>
  );
}
