import React, { useContext, useState } from 'react';
// Import responsive design
import { useMediaQuery } from 'react-responsive';

// Import styles
import './App.css';
import { styled } from '@mui/material/styles';

import { getAnalytics, logEvent } from 'firebase/analytics';

// Import Search functionality
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Configure,
  ExperimentalDynamicWidgets,
  RefinementList,
  Pagination,
  ToggleRefinement,
  Stats,
} from 'react-instantsearch-dom';
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
  // CustomMarker,
} from 'react-instantsearch-dom-maps';
import PropTypes from 'prop-types';

// Import components
import ProductDetail from './components/productDetail/ProductDetail';
import ShopDetail from './components/ShopDetail';
import logo from './assets/logo_web.png';
import logo2 from './assets/logo_web.svg';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import ColorToggleButton from './components/ToggleButton';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import ShopsPage from './components/shopsPage/ShopsPage';
import ShopDistance from './components/ShopDistance';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CookieConsentComponent from './components/CookieConsent';
import Reserve from './components/buttons/Reserve';
import Save from './components/buttons/Save';
import PrivacyNotice from './components/PrivacyNotice';
import StoreIcon from '@mui/icons-material/Store';
import { InStock, NotInStock } from './components/buttons/Stock';
import Login from './components/login/Login';
import Signup from './components/signup/Signup';
import UserProfile from './components/userProfile/UserProfile';
import Reserved from './components/userProfile/Reserved';
import ShopButtonNew from './components/buttons/ShopButton';
import About from './components/about/About';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './components/sidebar/Sidebar';
import Footer from './components/footer/Footer';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';

// Import functions
import { numberFormat } from './Functions/numberFormatting';
// import firebase from 'firebase/compat/app';

// Import Routing & context
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
} from 'react-router-dom';
import { GlobalContext } from './context/GlobalState.js';

// Hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';

import ReactGA from 'react-ga4';
import { API_KEY } from './API_KEY';

// Initialize Algolia search
// const searchClient = algoliasearch(
//   process.env.REACT_APP_A_ID,
//   process.env.REACT_APP_A_KEY
// );
const searchClient = algoliasearch(
  'AW9WPTNFSH',
  '67bdb265bb64bd0ada4464dd88b38cd0'
);

// Style button
const ShopButton = styled(Button)({
  fontSize: 12,
  textTransform: 'none',
  '&:hover': {
    // backgroundColor: '#0069d9',
    // borderColor: '#0062cc',
    // boxShadow: 'none',
  },
});

// --> Local
// const API_KEY = process.env.REACT_APP_GM_KEY;

// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA);
ReactGA.initialize('G-1H6S1G5NXR');
ReactGA.send('pageview');

// Main App
function App() {
  const isDesktop = useMediaQuery({ query: '(min-width: 1171px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const [main, setMain] = useState(true);
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const { authIsReady } = useAuthContext();
  // const [toggleStateShops, setToggleStateShops] = useState(true);
  const [mobileFilter, setMobileFilter] = useState(false);
  // const [initialSearch, setInitialSearch] = useState(none);

  // for geolocation
  const [lat, setLat] = useState(55.9533);
  const [lng, setLng] = useState(3.1883);
  const [status, setStatus] = useState(null);

  // const context = useContext(GlobalContext);

  const { updateLat } = useContext(GlobalContext);
  const { updateLng } = useContext(GlobalContext);

  ReactGA.pageview('/');

  const getLocation = async () => {
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
    } else {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition(
        position => {
          setStatus(null);

          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          updateLat(position.coords.latitude);
          updateLng(position.coords.longitude);
        },
        () => {
          setStatus('Unable to retrieve your location');
        }
      );
    }
  };

  const handleMobileFilterOn = e => {
    e.preventDefault();
    setMobileFilter(true);
  };

  const handleMobileFilterOff = e => {
    e.preventDefault();
    setMobileFilter(false);
  };

  // initial ui for instantsearch objects --> ads
  const hiking = {
    products: {
      query: 'hiking',
    },
  };

  const backpack = {
    products: {
      query: 'backpack',
    },
  };

  const beanie = {
    products: {
      query: 'beanie',
    },
  };

  return (
    <Router>
      {/* Initiate Instant search*/}

      <CookieConsentComponent />
      {authIsReady && (
        <>
          <div className="main">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <InstantSearch
                    searchClient={searchClient}
                    indexName="products"
                  >
                    <div>
                      <header className="header">
                        <Stack direction="row" spacing={2}>
                          <Link to={'/'}>
                            <div className="image_container">
                              <img
                                className="header_logo"
                                src={logo2}
                                alt="logo"
                              />
                            </div>
                          </Link>
                          <SearchBox
                            className="searchbox"
                            searchAsYouType={false}
                            translations={{
                              submitTitle: 'Submit your search query.',
                              resetTitle: 'Clear your search query.',
                              placeholder: 'Search products here...',
                            }}
                          />
                          {isDesktop && (
                            <div className="location">
                              <LocationOnIcon />
                              <div> Edinburgh </div>
                            </div>
                          )}
                          {isDesktop && (
                            <div className="geo-location">
                              <Button
                                className="location-button"
                                variant="outlined"
                                onClick={getLocation}
                              >
                                Use Current Location
                              </Button>
                              <p className="locating-in-progress">{status}</p>
                            </div>
                          )}
                          {isMobile && (
                            <div className="burger-container">
                              {/* <MenuIcon fontSize="large" onClick={setSidebar} /> */}
                              <Sidebar
                                logout={logout}
                                getLocation={getLocation}
                                status={status}
                              />
                            </div>
                          )}
                        </Stack>
                        {/* <SearchBox translations={{placeholder: 'Search for products',}}/> */}
                        {isDesktop && (
                          <div className="menu-box">
                            <Link
                              className="astext"
                              to={'/shops'}
                              style={{ textDecoration: 'none' }}
                            >
                              Shops
                            </Link>
                            <a
                              className="astext"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://docs.google.com/forms/d/e/1FAIpQLSeOA26a2wCxOFeVnfLnHpq5UedXxaQVbS2ueemZ3jTQhoMTjQ/viewform?usp=sf_link"
                            >
                              Give Feedback
                            </a>
                            <Link
                              className="astext"
                              to={'/about'}
                              style={{ textDecoration: 'none' }}
                            >
                              About
                            </Link>
                            <div className="astext">|</div>
                            {!user && (
                              <>
                                <Link
                                  className="astext"
                                  to={'/login'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Login
                                </Link>
                                <Link
                                  className="astext"
                                  to={'/signup'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Sign up
                                </Link>
                              </>
                            )}
                            {user && (
                              <>
                                {/* <div className="astext">{user.displayName}</div> */}
                                <Link
                                  className="astext"
                                  // to={'/' + user.displayName}
                                  to={'/favorites'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  My Favorites
                                </Link>
                                <Link
                                  className="astext"
                                  // to={'/' + user.displayName}
                                  to={'/reserve'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Reserved items
                                </Link>
                                <div className="astext" onClick={logout}>
                                  Logout
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </header>

                      {isDesktop && (
                        <div className="banner">
                          <h1 className="head-title">
                            The search engine for local shops
                          </h1>
                          <h2>
                            Find what you need online and then buy it from a
                            shop near you.{' '}
                          </h2>
                        </div>
                      )}
                      {isMobile && (
                        <div className="banner-mobile">
                          <div className="h1-mobile">
                            The search engine for local shops
                          </div>
                          <div className="location-on-mobile">
                            <LocationOnIcon />
                            <div> Edinburgh </div>
                          </div>
                          <div className="h2-mobile">
                            Find what you need online and then buy it from a
                            shop near you.{' '}
                          </div>
                        </div>
                      )}

                      <RefinementList attribute="product_description" />

                      <div className="container">
                        <div
                          className={
                            isDesktop ? 'search-panel' : 'search-panel-mobile'
                          }
                        >
                          {isDesktop && (
                            <div className="search-panel-filters">
                              <h2>Filters</h2>
                              <div className="filter-tile">
                                <b>In Stock</b>
                                <ToggleRefinement
                                  attribute={'in_stock_boolean'}
                                  label={'currently available'}
                                  value={true}
                                />
                              </div>
                              <div className="filter-tile">
                                <b>Shops</b>
                                <RefinementList
                                  attribute="shop_name"
                                  operator="and"
                                  limit={5}
                                  showMore
                                  showMoreLimit={35}
                                />
                              </div>
                              <div className="filter-tile">
                                <b>Brands</b>
                                <RefinementList
                                  attribute="brand"
                                  operator="and"
                                  limit={5}
                                  showMore
                                  showMoreLimit={20}
                                />
                              </div>
                            </div>
                          )}
                          {isMobile && (
                            <>
                              <div
                                className={
                                  mobileFilter
                                    ? 'mobile-filter-container'
                                    : 'mobile-filter-container-off'
                                }
                              >
                                <div
                                  className={
                                    mobileFilter
                                      ? 'search-panel-filters-mobile'
                                      : 'search-panel-filters-mobile-off'
                                  }
                                >
                                  <div
                                    className="filter-header-mobile"
                                    onClick={handleMobileFilterOff}
                                  >
                                    <div className="filter-header">Filters</div>
                                    <CloseIcon />
                                  </div>
                                  <div
                                    className={
                                      mobileFilter
                                        ? 'filter-scroll-area'
                                        : 'filter-scroll-area-off'
                                    }
                                  >
                                    <div className="filter-tile">
                                      <b>In Stock</b>
                                      <ToggleRefinement
                                        attribute={'in_stock_boolean'}
                                        label={'currently available'}
                                        value={true}
                                      />
                                    </div>
                                    <div className="filter-tile">
                                      <b>Shops</b>
                                      <RefinementList
                                        attribute="shop_name"
                                        operator="and"
                                        limit={5}
                                        showMore
                                        showMoreLimit={35}
                                      />
                                    </div>
                                    <div className="filter-tile">
                                      <b>Brands</b>
                                      <RefinementList
                                        attribute="brand"
                                        operator="and"
                                        limit={5}
                                        showMore
                                        showMoreLimit={20}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="search-panel__results">
                            <div className="info">
                              <Stats />
                            </div>
                            <Hits hitComponent={Hit} />

                            <div className="pagination">
                              <Pagination />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div style={{ height: 500 }}>
                          <GoogleMapsLoader apiKey={API_KEY}>
                            {google => (
                              <GeoSearch google={google} initialZoom={11}>
                                {({ hits }) => (
                                  <div>
                                    {hits.map(hit => (
                                      <Marker key={hit.objectID} hit={hit} />
                                    ))}
                                  </div>
                                )}
                              </GeoSearch>
                            )}
                          </GoogleMapsLoader>
                        </div>
                        {isMobile && (
                          <aside>
                            <Button
                              variant="contained"
                              className="filters-button"
                              onClick={handleMobileFilterOn}
                            >
                              <FilterAltIcon className="cart-icon" />
                              <p>Filters</p>
                            </Button>
                          </aside>
                        )}
                      </div>
                      <Footer />
                    </div>
                  </InstantSearch>
                }
              />
              <Route
                exact
                path="/productDetail/:id"
                element={<ProductDetail header="false" />}
                onClick={() => setMain(false)}
              />
              <Route
                exact
                path="/shops/:id"
                element={<ShopDetail header="true" type="shop-detail" />}
                onClick={() => setMain(false)}
              />
              <Route
                exact
                path="/shops"
                element={<ShopsPage header="true" />}
              />
              <Route
                exact
                path="/privacy"
                element={<PrivacyNotice header="true" />}
              />
              <Route
                exact
                path="/login"
                element={
                  !user ? <Login header="true" /> : <Navigate replace to="/" />
                }
              />
              <Route
                exact
                path="/signup"
                element={
                  !user ? <Signup header="true" /> : <Navigate replace to="/" />
                }
              />
              <Route
                exact
                path="/:user"
                element={
                  !user ? (
                    <Navigate replace to="/" />
                  ) : (
                    <UserProfile header="true" />
                  )
                }
              />
              <Route
                exact
                path="/favorites"
                element={
                  !user ? (
                    <Navigate replace to="/" />
                  ) : (
                    <UserProfile header="true" />
                  )
                }
              />
              <Route
                exact
                path="/reserve"
                element={
                  !user ? (
                    <Navigate replace to="/" />
                  ) : (
                    <Reserved header="true" />
                  )
                }
              />
              <Route exact path="/about" element={<About header="true" />} />
              {/* For Ads */}

              <Route
                exact
                path="/ads/hiking"
                element={
                  <InstantSearch
                    searchClient={searchClient}
                    indexName="products"
                    initialUiState={hiking}
                  >
                    <div>
                      <header className="header">
                        <Stack direction="row" spacing={2}>
                          <Link to={'/'}>
                            <div className="image_container">
                              <img
                                className="header_logo"
                                src={logo2}
                                alt="logo"
                              />
                            </div>
                          </Link>
                          <SearchBox
                            className="searchbox"
                            searchAsYouType={false}
                            defaultRefinement="Hiking"
                            translations={{
                              submitTitle: 'Submit your search query.',
                              resetTitle: 'Clear your search query.',
                              placeholder: 'Search products here...',
                            }}
                          />
                          {isDesktop && (
                            <div className="location">
                              <LocationOnIcon />
                              <div> Edinburgh </div>
                            </div>
                          )}
                          {isDesktop && (
                            <div className="geo-location">
                              <Button
                                className="location-button"
                                variant="outlined"
                                onClick={getLocation}
                              >
                                Use Current Location
                              </Button>
                              <p className="locating-in-progress">{status}</p>
                            </div>
                          )}
                          {isMobile && (
                            <div className="burger-container">
                              {/* <MenuIcon fontSize="large" onClick={setSidebar} /> */}
                              <Sidebar
                                logout={logout}
                                getLocation={getLocation}
                                status={status}
                              />
                            </div>
                          )}
                        </Stack>
                        {/* <SearchBox translations={{placeholder: 'Search for products',}}/> */}
                        {isDesktop && (
                          <div className="menu-box">
                            <Link
                              className="astext"
                              to={'/shops'}
                              style={{ textDecoration: 'none' }}
                            >
                              Shops
                            </Link>
                            <a
                              className="astext"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://docs.google.com/forms/d/e/1FAIpQLSeOA26a2wCxOFeVnfLnHpq5UedXxaQVbS2ueemZ3jTQhoMTjQ/viewform?usp=sf_link"
                            >
                              Give Feedback
                            </a>
                            <Link
                              className="astext"
                              to={'/about'}
                              style={{ textDecoration: 'none' }}
                            >
                              About
                            </Link>
                            <div className="astext">|</div>
                            {!user && (
                              <>
                                <Link
                                  className="astext"
                                  to={'/login'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Login
                                </Link>
                                <Link
                                  className="astext"
                                  to={'/signup'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Sign up
                                </Link>
                              </>
                            )}
                            {user && (
                              <>
                                {/* <div className="astext">{user.displayName}</div> */}
                                <Link
                                  className="astext"
                                  // to={'/' + user.displayName}
                                  to={'/favorites'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  My Favorites
                                </Link>
                                <Link
                                  className="astext"
                                  // to={'/' + user.displayName}
                                  to={'/reserve'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Reserved items
                                </Link>
                                <div className="astext" onClick={logout}>
                                  Logout
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </header>

                      {isDesktop && (
                        <div className="banner">
                          <h1 className="head-title">
                            The search engine for local shops
                          </h1>
                          <h2>
                            Find what you need online and then buy it from a
                            shop near you.{' '}
                          </h2>
                        </div>
                      )}
                      {isMobile && (
                        <div className="banner-mobile">
                          <div className="h1-mobile">
                            The search engine for local shops
                          </div>
                          <div className="location-on-mobile">
                            <LocationOnIcon />
                            <div> Edinburgh </div>
                          </div>
                          <div className="h2-mobile">
                            Find what you need online and then buy it from a
                            shop near you.{' '}
                          </div>
                        </div>
                      )}

                      <RefinementList attribute="product_description" />

                      <div className="container">
                        <div
                          className={
                            isDesktop ? 'search-panel' : 'search-panel-mobile'
                          }
                        >
                          {isDesktop && (
                            <div className="search-panel-filters">
                              <h2>Filters</h2>
                              <div className="filter-tile">
                                <b>In Stock</b>
                                <ToggleRefinement
                                  attribute={'in_stock_boolean'}
                                  label={'currently available'}
                                  value={true}
                                />
                              </div>
                              <div className="filter-tile">
                                <b>Shops</b>
                                <RefinementList
                                  attribute="shop_name"
                                  operator="and"
                                  limit={5}
                                  showMore
                                  showMoreLimit={35}
                                />
                              </div>
                              <div className="filter-tile">
                                <b>Brands</b>
                                <RefinementList
                                  attribute="brand"
                                  operator="and"
                                  limit={5}
                                  showMore
                                  showMoreLimit={20}
                                />
                              </div>
                            </div>
                          )}
                          {isMobile && (
                            <>
                              <div
                                className={
                                  mobileFilter
                                    ? 'mobile-filter-container'
                                    : 'mobile-filter-container-off'
                                }
                              >
                                <div
                                  className={
                                    mobileFilter
                                      ? 'search-panel-filters-mobile'
                                      : 'search-panel-filters-mobile-off'
                                  }
                                >
                                  <div
                                    className="filter-header-mobile"
                                    onClick={handleMobileFilterOff}
                                  >
                                    <div className="filter-header">Filters</div>
                                    <CloseIcon />
                                  </div>
                                  <div
                                    className={
                                      mobileFilter
                                        ? 'filter-scroll-area'
                                        : 'filter-scroll-area-off'
                                    }
                                  >
                                    <div className="filter-tile">
                                      <b>In Stock</b>
                                      <ToggleRefinement
                                        attribute={'in_stock_boolean'}
                                        label={'currently available'}
                                        value={true}
                                      />
                                    </div>
                                    <div className="filter-tile">
                                      <b>Shops</b>
                                      <RefinementList
                                        attribute="shop_name"
                                        operator="and"
                                        limit={5}
                                        showMore
                                        showMoreLimit={35}
                                      />
                                    </div>
                                    <div className="filter-tile">
                                      <b>Brands</b>
                                      <RefinementList
                                        attribute="brand"
                                        operator="and"
                                        limit={5}
                                        showMore
                                        showMoreLimit={20}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="search-panel__results">
                            <div className="info">
                              <Stats />
                            </div>
                            <Hits hitComponent={Hit} />

                            <div className="pagination">
                              <Pagination />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div style={{ height: 500 }}>
                          <GoogleMapsLoader apiKey={API_KEY}>
                            {google => (
                              <GeoSearch google={google} initialZoom={11}>
                                {({ hits }) => (
                                  <div>
                                    {hits.map(hit => (
                                      <Marker key={hit.objectID} hit={hit} />
                                    ))}
                                  </div>
                                )}
                              </GeoSearch>
                            )}
                          </GoogleMapsLoader>
                        </div>
                        {isMobile && (
                          <aside>
                            <Button
                              variant="contained"
                              className="filters-button"
                              onClick={handleMobileFilterOn}
                            >
                              <FilterAltIcon className="cart-icon" />
                              <p>Filters</p>
                            </Button>
                          </aside>
                        )}
                      </div>
                      <Footer />
                    </div>
                  </InstantSearch>
                }
              />
              <Route
                exact
                path="/ads/backpack"
                element={
                  <InstantSearch
                    searchClient={searchClient}
                    indexName="products"
                    initialUiState={backpack}
                  >
                    <div>
                      <header className="header">
                        <Stack direction="row" spacing={2}>
                          <Link to={'/'}>
                            <div className="image_container">
                              <img
                                className="header_logo"
                                src={logo2}
                                alt="logo"
                              />
                            </div>
                          </Link>
                          <SearchBox
                            className="searchbox"
                            searchAsYouType={false}
                            defaultRefinement="Backpack"
                            translations={{
                              submitTitle: 'Submit your search query.',
                              resetTitle: 'Clear your search query.',
                              placeholder: 'Search products here...',
                            }}
                          />
                          {isDesktop && (
                            <div className="location">
                              <LocationOnIcon />
                              <div> Edinburgh </div>
                            </div>
                          )}
                          {isDesktop && (
                            <div className="geo-location">
                              <Button
                                className="location-button"
                                variant="outlined"
                                onClick={getLocation}
                              >
                                Use Current Location
                              </Button>
                              <p className="locating-in-progress">{status}</p>
                            </div>
                          )}
                          {isMobile && (
                            <div className="burger-container">
                              {/* <MenuIcon fontSize="large" onClick={setSidebar} /> */}
                              <Sidebar
                                logout={logout}
                                getLocation={getLocation}
                                status={status}
                              />
                            </div>
                          )}
                        </Stack>
                        {/* <SearchBox translations={{placeholder: 'Search for products',}}/> */}
                        {isDesktop && (
                          <div className="menu-box">
                            <Link
                              className="astext"
                              to={'/shops'}
                              style={{ textDecoration: 'none' }}
                            >
                              Shops
                            </Link>
                            <a
                              className="astext"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://docs.google.com/forms/d/e/1FAIpQLSeOA26a2wCxOFeVnfLnHpq5UedXxaQVbS2ueemZ3jTQhoMTjQ/viewform?usp=sf_link"
                            >
                              Give Feedback
                            </a>
                            <Link
                              className="astext"
                              to={'/about'}
                              style={{ textDecoration: 'none' }}
                            >
                              About
                            </Link>
                            <div className="astext">|</div>
                            {!user && (
                              <>
                                <Link
                                  className="astext"
                                  to={'/login'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Login
                                </Link>
                                <Link
                                  className="astext"
                                  to={'/signup'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Sign up
                                </Link>
                              </>
                            )}
                            {user && (
                              <>
                                {/* <div className="astext">{user.displayName}</div> */}
                                <Link
                                  className="astext"
                                  // to={'/' + user.displayName}
                                  to={'/favorites'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  My Favorites
                                </Link>
                                <Link
                                  className="astext"
                                  // to={'/' + user.displayName}
                                  to={'/reserve'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Reserved items
                                </Link>
                                <div className="astext" onClick={logout}>
                                  Logout
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </header>

                      {isDesktop && (
                        <div className="banner">
                          <h1 className="head-title">
                            The search engine for local shops
                          </h1>
                          <h2>
                            Find what you need online and then buy it from a
                            shop near you.{' '}
                          </h2>
                        </div>
                      )}
                      {isMobile && (
                        <div className="banner-mobile">
                          <div className="h1-mobile">
                            The search engine for local shops
                          </div>
                          <div className="location-on-mobile">
                            <LocationOnIcon />
                            <div> Edinburgh </div>
                          </div>
                          <div className="h2-mobile">
                            Find what you need online and then buy it from a
                            shop near you.{' '}
                          </div>
                        </div>
                      )}

                      <RefinementList attribute="product_description" />

                      <div className="container">
                        <div
                          className={
                            isDesktop ? 'search-panel' : 'search-panel-mobile'
                          }
                        >
                          {isDesktop && (
                            <div className="search-panel-filters">
                              <h2>Filters</h2>
                              <div className="filter-tile">
                                <b>In Stock</b>
                                <ToggleRefinement
                                  attribute={'in_stock_boolean'}
                                  label={'currently available'}
                                  value={true}
                                />
                              </div>
                              <div className="filter-tile">
                                <b>Shops</b>
                                <RefinementList
                                  attribute="shop_name"
                                  operator="and"
                                  limit={5}
                                  showMore
                                  showMoreLimit={35}
                                />
                              </div>
                              <div className="filter-tile">
                                <b>Brands</b>
                                <RefinementList
                                  attribute="brand"
                                  operator="and"
                                  limit={5}
                                  showMore
                                  showMoreLimit={20}
                                />
                              </div>
                            </div>
                          )}
                          {isMobile && (
                            <>
                              <div
                                className={
                                  mobileFilter
                                    ? 'mobile-filter-container'
                                    : 'mobile-filter-container-off'
                                }
                              >
                                <div
                                  className={
                                    mobileFilter
                                      ? 'search-panel-filters-mobile'
                                      : 'search-panel-filters-mobile-off'
                                  }
                                >
                                  <div
                                    className="filter-header-mobile"
                                    onClick={handleMobileFilterOff}
                                  >
                                    <div className="filter-header">Filters</div>
                                    <CloseIcon />
                                  </div>
                                  <div
                                    className={
                                      mobileFilter
                                        ? 'filter-scroll-area'
                                        : 'filter-scroll-area-off'
                                    }
                                  >
                                    <div className="filter-tile">
                                      <b>In Stock</b>
                                      <ToggleRefinement
                                        attribute={'in_stock_boolean'}
                                        label={'currently available'}
                                        value={true}
                                      />
                                    </div>
                                    <div className="filter-tile">
                                      <b>Shops</b>
                                      <RefinementList
                                        attribute="shop_name"
                                        operator="and"
                                        limit={5}
                                        showMore
                                        showMoreLimit={35}
                                      />
                                    </div>
                                    <div className="filter-tile">
                                      <b>Brands</b>
                                      <RefinementList
                                        attribute="brand"
                                        operator="and"
                                        limit={5}
                                        showMore
                                        showMoreLimit={20}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="search-panel__results">
                            <div className="info">
                              <Stats />
                            </div>
                            <Hits hitComponent={Hit} />

                            <div className="pagination">
                              <Pagination />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div style={{ height: 500 }}>
                          <GoogleMapsLoader apiKey={API_KEY}>
                            {google => (
                              <GeoSearch google={google} initialZoom={11}>
                                {({ hits }) => (
                                  <div>
                                    {hits.map(hit => (
                                      <Marker key={hit.objectID} hit={hit} />
                                    ))}
                                  </div>
                                )}
                              </GeoSearch>
                            )}
                          </GoogleMapsLoader>
                        </div>
                        {isMobile && (
                          <aside>
                            <Button
                              variant="contained"
                              className="filters-button"
                              onClick={handleMobileFilterOn}
                            >
                              <FilterAltIcon className="cart-icon" />
                              <p>Filters</p>
                            </Button>
                          </aside>
                        )}
                      </div>
                      <Footer />
                    </div>
                  </InstantSearch>
                }
              />
              <Route
                exact
                path="/ads/beanie"
                element={
                  <InstantSearch
                    searchClient={searchClient}
                    indexName="products"
                    initialUiState={beanie}
                  >
                    <div>
                      <header className="header">
                        <Stack direction="row" spacing={2}>
                          <Link to={'/'}>
                            <div className="image_container">
                              <img
                                className="header_logo"
                                src={logo2}
                                alt="logo"
                              />
                            </div>
                          </Link>
                          <SearchBox
                            className="searchbox"
                            searchAsYouType={false}
                            defaultRefinement="Beanie"
                            translations={{
                              submitTitle: 'Submit your search query.',
                              resetTitle: 'Clear your search query.',
                              placeholder: 'Search products here...',
                            }}
                          />
                          {isDesktop && (
                            <div className="location">
                              <LocationOnIcon />
                              <div> Edinburgh </div>
                            </div>
                          )}
                          {isDesktop && (
                            <div className="geo-location">
                              <Button
                                className="location-button"
                                variant="outlined"
                                onClick={getLocation}
                              >
                                Use Current Location
                              </Button>
                              <p className="locating-in-progress">{status}</p>
                            </div>
                          )}
                          {isMobile && (
                            <div className="burger-container">
                              {/* <MenuIcon fontSize="large" onClick={setSidebar} /> */}
                              <Sidebar
                                logout={logout}
                                getLocation={getLocation}
                                status={status}
                              />
                            </div>
                          )}
                        </Stack>
                        {/* <SearchBox translations={{placeholder: 'Search for products',}}/> */}
                        {isDesktop && (
                          <div className="menu-box">
                            <Link
                              className="astext"
                              to={'/shops'}
                              style={{ textDecoration: 'none' }}
                            >
                              Shops
                            </Link>
                            <a
                              className="astext"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://docs.google.com/forms/d/e/1FAIpQLSeOA26a2wCxOFeVnfLnHpq5UedXxaQVbS2ueemZ3jTQhoMTjQ/viewform?usp=sf_link"
                            >
                              Give Feedback
                            </a>
                            <Link
                              className="astext"
                              to={'/about'}
                              style={{ textDecoration: 'none' }}
                            >
                              About
                            </Link>
                            <div className="astext">|</div>
                            {!user && (
                              <>
                                <Link
                                  className="astext"
                                  to={'/login'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Login
                                </Link>
                                <Link
                                  className="astext"
                                  to={'/signup'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Sign up
                                </Link>
                              </>
                            )}
                            {user && (
                              <>
                                {/* <div className="astext">{user.displayName}</div> */}
                                <Link
                                  className="astext"
                                  // to={'/' + user.displayName}
                                  to={'/favorites'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  My Favorites
                                </Link>
                                <Link
                                  className="astext"
                                  // to={'/' + user.displayName}
                                  to={'/reserve'}
                                  style={{ textDecoration: 'none' }}
                                >
                                  Reserved items
                                </Link>
                                <div className="astext" onClick={logout}>
                                  Logout
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </header>

                      {isDesktop && (
                        <div className="banner">
                          <h1 className="head-title">
                            The search engine for local shops
                          </h1>
                          <h2>
                            Find what you need online and then buy it from a
                            shop near you.{' '}
                          </h2>
                        </div>
                      )}
                      {isMobile && (
                        <div className="banner-mobile">
                          <div className="h1-mobile">
                            The search engine for local shops
                          </div>
                          <div className="location-on-mobile">
                            <LocationOnIcon />
                            <div> Edinburgh </div>
                          </div>
                          <div className="h2-mobile">
                            Find what you need online and then buy it from a
                            shop near you.{' '}
                          </div>
                        </div>
                      )}

                      <RefinementList attribute="product_description" />

                      <div className="container">
                        <div
                          className={
                            isDesktop ? 'search-panel' : 'search-panel-mobile'
                          }
                        >
                          {isDesktop && (
                            <div className="search-panel-filters">
                              <h2>Filters</h2>
                              <div className="filter-tile">
                                <b>In Stock</b>
                                <ToggleRefinement
                                  attribute={'in_stock_boolean'}
                                  label={'currently available'}
                                  value={true}
                                />
                              </div>
                              <div className="filter-tile">
                                <b>Shops</b>
                                <RefinementList
                                  attribute="shop_name"
                                  operator="and"
                                  limit={5}
                                  showMore
                                  showMoreLimit={35}
                                />
                              </div>
                              <div className="filter-tile">
                                <b>Brands</b>
                                <RefinementList
                                  attribute="brand"
                                  operator="and"
                                  limit={5}
                                  showMore
                                  showMoreLimit={20}
                                />
                              </div>
                            </div>
                          )}
                          {isMobile && (
                            <>
                              <div
                                className={
                                  mobileFilter
                                    ? 'mobile-filter-container'
                                    : 'mobile-filter-container-off'
                                }
                              >
                                <div
                                  className={
                                    mobileFilter
                                      ? 'search-panel-filters-mobile'
                                      : 'search-panel-filters-mobile-off'
                                  }
                                >
                                  <div
                                    className="filter-header-mobile"
                                    onClick={handleMobileFilterOff}
                                  >
                                    <div className="filter-header">Filters</div>
                                    <CloseIcon />
                                  </div>
                                  <div
                                    className={
                                      mobileFilter
                                        ? 'filter-scroll-area'
                                        : 'filter-scroll-area-off'
                                    }
                                  >
                                    <div className="filter-tile">
                                      <b>In Stock</b>
                                      <ToggleRefinement
                                        attribute={'in_stock_boolean'}
                                        label={'currently available'}
                                        value={true}
                                      />
                                    </div>
                                    <div className="filter-tile">
                                      <b>Shops</b>
                                      <RefinementList
                                        attribute="shop_name"
                                        operator="and"
                                        limit={5}
                                        showMore
                                        showMoreLimit={35}
                                      />
                                    </div>
                                    <div className="filter-tile">
                                      <b>Brands</b>
                                      <RefinementList
                                        attribute="brand"
                                        operator="and"
                                        limit={5}
                                        showMore
                                        showMoreLimit={20}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="search-panel__results">
                            <div className="info">
                              <Stats />
                            </div>
                            <Hits hitComponent={Hit} />

                            <div className="pagination">
                              <Pagination />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div style={{ height: 500 }}>
                          <GoogleMapsLoader apiKey={API_KEY}>
                            {google => (
                              <GeoSearch google={google} initialZoom={11}>
                                {({ hits }) => (
                                  <div>
                                    {hits.map(hit => (
                                      <Marker key={hit.objectID} hit={hit} />
                                    ))}
                                  </div>
                                )}
                              </GeoSearch>
                            )}
                          </GoogleMapsLoader>
                        </div>
                        {isMobile && (
                          <aside>
                            <Button
                              variant="contained"
                              className="filters-button"
                              onClick={handleMobileFilterOn}
                            >
                              <FilterAltIcon className="cart-icon" />
                              <p>Filters</p>
                            </Button>
                          </aside>
                        )}
                      </div>
                      <Footer />
                    </div>
                  </InstantSearch>
                }
              />
            </Routes>
          </div>
        </>
      )}
    </Router>
  );
}

function Hit(props) {
  // Set up context
  // const context = useContext(GlobalContext);

  const { updateProduct } = useContext(GlobalContext);

  // On click - update context
  const onClick = e => {
    // e.preventDefault();
    const analytics = getAnalytics();
    logEvent(analytics, 'product_clicked');

    // const geoloc_product = new firebase.firestore.GeoPoint(
    //   props.hit.lat,
    //   props.hit.lng
    // );

    const newProduct = {
      id: props.hit.objectID,
      name: props.hit.product_name,
      price: numberFormat(props.hit.product_price / 100),
      producer: props.hit.brand,
      description: props.hit.product_description,
      image: props.hit.product_image,
      shop: props.hit.shop_reference,
      lat: props.hit._geoloc.lat,
      lng: props.hit._geoloc.lng,
      shopName: props.hit.shop_name,
      inStock: props.hit.in_stock_boolean,
      productType: props.hit.product_type,
      productUrl: props.hit.url,
    };
    updateProduct(newProduct);
  };

  const shop = props.hit.shop_reference.replace('/shops/', '');

  return (
    <Link
      to={`/ProductDetail/${props.hit.objectID}`}
      style={{ textDecoration: 'none' }}
      shop={props.hit.shop_reference}
    >
      <div className="product-tile" onClick={onClick}>
        {/* Product image */}
        <div className="hit-image">
          <img
            className="image"
            src={props.hit.product_image}
            align="left"
            alt={props.hit.product_name}
          />
        </div>

        {/* Tile information */}
        <div className="hit-content">
          <div className="product-information">
            <div className="hit-product-name">{props.hit.product_name}</div>
            <div className="price-stock-box">
              <div className="hit-price">
                {numberFormat(props.hit.product_price / 100)}
              </div>
              {props.hit.in_stock_boolean && <InStock />}
              {!props.hit.in_stock_boolean && <NotInStock />}
            </div>
            <div className="button-container">
              <Reserve
                id={props.hit.objectID}
                name={props.hit.product_name}
                price={numberFormat(props.hit.product_price / 100)}
                producer={props.hit.brand}
                description={props.hit.product_description}
                image={props.hit.product_image}
                shop={shop}
                lat={props.hit._geoloc.lat}
                lng={props.hit._geoloc.lng}
                shopName={props.hit.shop_name}
                inStock={props.hit.in_stock_boolean}
                productType={props.hit.product_type}
                productUrl={props.hit.url}
              />
              <Save
                id={props.hit.objectID}
                name={props.hit.product_name}
                price={numberFormat(props.hit.product_price / 100)}
                producer={props.hit.brand}
                description={props.hit.product_description}
                image={props.hit.product_image}
                shop={shop}
                lat={props.hit._geoloc.lat}
                lng={props.hit._geoloc.lng}
                shopName={props.hit.shop_name}
                inStock={props.hit.in_stock_boolean}
                productType={props.hit.product_type}
                productUrl={props.hit.url}
              />
            </div>
          </div>
          <ShopButtonNew
            name={props.hit.shop_name}
            lat={props.hit._geoloc.lat}
            lng={props.hit._geoloc.lng}
            sref={props.hit.shop_reference}
          />
        </div>
      </div>
    </Link>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

// Sidebar
// const Sidebar = () => {
//   <div className="sidebar">
//     <h5>Brand</h5>
//     <RefinementList attributeName="producer" withSearchBox/>
//   </div>
// }

export default App;
