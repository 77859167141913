import moment from 'moment-timezone';

export function isStoreOpen(times) {
  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  const day = days[moment().day()];
  const openingKey = `${day}_o`;
  const closingKey = `${day}_c`;
  const opens = `${times[openingKey]}:00`;
  const closes = `${times[closingKey]}:00`;
  const d = new Date();
  const currentTime = d.toLocaleTimeString();

  if (currentTime >= opens && currentTime <= closes) {
    return true;
  }

  return false;
}
