import React, { useContext } from 'react';
import '../../App.css';
import { InStock, NotInStock } from '../buttons/Stock';
import { styled } from '@mui/material/styles';
import ShopButtonNew from '../buttons/ShopButton';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalState.js';
import Reserve from '../buttons/Reserve';
import Save from '../buttons/Save';
import ReserveTrash from '../buttons/ReserveTrash';

export default function ReservedTile({ product, deleteDocument }) {
  const { updateProduct } = useContext(GlobalContext);

  // On click - update context
  const onClick = e => {
    const newProduct = {
      id: product.id,
      name: product.name,
      price: product.price,
      producer: product.producer,
      description: product.description,
      image: product.image,
      shop: product.shop,
      lat: product.lat,
      lng: product.lng,
      shopName: product.shopName,
      inStock: product.inStock,
      productType: product.productType,
      productUrl: product.productUrl,
    };
    updateProduct(newProduct);
  };

  console.log('RESERVED TILE');

  return (
    <div>
      <Link
        to={'/ProductDetail/' + product.id}
        style={{ textDecoration: 'none' }}
        shop={product.shop}
      >
        <div className="product-tile" onClick={onClick}>
          {/* Product image */}
          <div className="hit-image">
            <img
              className="image"
              src={product.image}
              align="left"
              alt={product.name}
            />
          </div>

          {/* Tile information */}
          <div className="hit-content">
            <div className="product-information">
              <div className="hit-product-name">{product.name}</div>
              <div className="price-stock-box">
                <div className="hit-price">{product.price}</div>
                {product.inStock && <InStock />}
                {!product.inStock && <NotInStock />}
              </div>
              <div className="button-container">
                <div className="reserve-status-box">
                  Reserve Status:
                  {product.state == 'Pending' && (
                    <div className="pending-item">{product.state}</div>
                  )}
                  {product.state !== 'Pending' && (
                    <div className="reserved-item">{product.state}</div>
                  )}
                </div>
                <ReserveTrash
                  deleteDocument={deleteDocument}
                  productId={product.id}
                />
              </div>
            </div>
            <ShopButtonNew
              name={product.shopName}
              lat={product.lat}
              lng={product.lng}
              sref={product.shop}
            />
          </div>
        </div>
      </Link>
    </div>
  );
}
