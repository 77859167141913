import React from 'react';
import IosShareIcon from '@mui/icons-material/IosShare';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Button from '@mui/material/Button';
import '../../App.css';

// Still needs to be tested

export default function Share({ product, shop, image }) {
  const handleShare = e => {
    const analytics = getAnalytics();
    logEvent(analytics, 'product_button_share');

    e.preventDefault();

    if (navigator.share) {
      navigator
        .share({
          title: `${product} from ${shop}`,
          text: `Check out ${product} from ${shop} on Plaza`,
          url: document.location.href,
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch(error => {
          console.error('Something went wrong sharing the blog', error);
        });
    }
  };

  return (
    <div>
      <Button className="share-button" onClick={handleShare}>
        <IosShareIcon className="share-border-icon" />
      </Button>
    </div>
  );
}
