import React from 'react';
import styles from './UserProfile.module.css';
import Header from '../header';
import ProductList from './ProductList';
import { useAuthContext } from '../../hooks/useAuthContext';

import { useCollection } from '../../hooks/useCollection';

export default function UserProfile() {
  const { user } = useAuthContext();
  const { documents, error } = useCollection(
    'saved_products',
    ['uid', '==', user.uid],
    ['createdAt', 'desc']
  );

  return (
    <div>
      <Header />
      <h2>My favorites</h2>
      {error && <p>{error}</p>}
      {documents && (
        <div className={styles.container}>
          <div className={styles.favorites}>
            <ProductList products={documents} />
          </div>
          {/* <div className={styles.reserved}>
            <p>Reserved Items</p>
            <ProductList products={documents} />
          </div> */}
        </div>
      )}
    </div>
  );
}
