import React from 'react';
import { useState } from 'react';
// import { GlobalContext } from '../context/GlobalState';
// import { giveOpenTime } from '../Functions/openTime';
import { useMediaQuery } from 'react-responsive';
import Stack from '@mui/material/Stack';
import '../App.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';
// import logo from '../assets/logo_web.png';

// Hooks
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';

function Header(params) {
  const [buttonAbout, setButtonAbout] = useState(false);
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  // const isMobile = useMediaQuery({ query: '(min-width: 320px)' });
  // const [main, setMain] = useState(true);
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  return (
    <div>
      <header className="header-two">
        <Stack direction="row" spacing={2}>
          {/* <Link to={'/'}> */}

          {/* <div className="image_container">
              <img className="header_logo" src={logo} alt="logo" />
            </div> */}
          <div className="arrow-div" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </div>
          {/* </Link> */}
        </Stack>
        {/* <SearchBox translations={{placeholder: 'Search for products',}}/> */}
        {isDesktop && (
          <div className="menu-box">
            <Link
              className="astext"
              to={'/shops'}
              style={{ textDecoration: 'none' }}
            >
              Shops
            </Link>
            <a
              className="astext"
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeOA26a2wCxOFeVnfLnHpq5UedXxaQVbS2ueemZ3jTQhoMTjQ/viewform?usp=sf_link"
            >
              Give Feedback
            </a>
            <div className="astext">|</div>
            {!user && (
              <>
                <Link
                  className="astext"
                  to={'/login'}
                  style={{ textDecoration: 'none' }}
                >
                  Login
                </Link>
                <Link
                  className="astext"
                  to={'/signup'}
                  style={{ textDecoration: 'none' }}
                >
                  Sign up
                </Link>
              </>
            )}
            {user && (
              <>
                {/* <div className="astext">{user.displayName}</div> */}
                <Link
                  className="astext"
                  to={'/' + user.displayName}
                  style={{ textDecoration: 'none' }}
                >
                  My Favorites
                </Link>
                <div className="astext" onClick={logout}>
                  Logout
                </div>
              </>
            )}
          </div>
        )}
      </header>
    </div>
  );
}

export default Header;
