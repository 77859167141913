import React from 'react';
import NavigationIcon from '@mui/icons-material/Navigation';
import Button from '@mui/material/Button';
import '../../App.css';

export default function ToShop({ lat, lng }) {
  // Function to navigate to Shop with Google Maps
  const goToGm = () => {
    window.open('https://maps.google.com?q=' + lat + ',' + lng);
  };
  return (
    <Button className="reserve-button-false" size="small" onClick={goToGm}>
      <NavigationIcon className="cart-icon" />
      Navigate to Shop
    </Button>
  );
}
