import firebase from 'firebase/compat/app';
import firestore from 'firebase/compat/firestore';
import { getAnalytics } from 'firebase/analytics';
import 'firebase/compat/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

// !!! From course

const firebaseConfig = {
  apiKey: 'AIzaSyDOy6VPuD8zVT9KR90nM4QUpmxlesKHSlE',
  authDomain: 'plaza-app-1018c.firebaseapp.com',
  projectId: 'plaza-app-1018c',
  storageBucket: 'plaza-app-1018c.appspot.com',
  messagingSenderId: '321102383283',
  appId: '1:321102383283:web:394364a3da1959cd5d88c6',
  measurementId: 'G-093CLTSTCH',
};

// init firebase
const app = firebase.initializeApp(firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LftGnceAAAAAGklOQAorV1bmwgMaj4u6Qoz34_i'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});
getAnalytics(app);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

// timestamp
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore, projectAuth, timestamp };
