import React, { useState, useEffect } from 'react';
import '../../App.css';
import Button from '@mui/material/Button';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useFirestore } from '../../hooks/useFirestore';
import { projectFirestore } from '../../Firebase/config';
import { arrayUnion, arrayRemove } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';

// Hooks
import { useAuthContext } from '../../hooks/useAuthContext';

export default function Save({
  id,
  name,
  price,
  producer,
  description,
  image,
  shop,
  lat,
  lng,
  shopName,
  inStock,
  productType,
  productUrl,
}) {
  const [saved, setSaved] = useState(false);
  const { addDocument, response } = useFirestore('saved_products');

  // Really unefficient to do this here - find solution to pass down in App.js
  // Then also insert it in Save({})
  const { user } = useAuthContext();

  // const productId = props.productId;
  // const shopId = props.shopId;

  const handleSave = e => {
    const analytics = getAnalytics();
    logEvent(analytics, 'product_button_save');

    e.preventDefault();

    const uid = user.uid;

    // Update Products collection
    // Waiting for db update

    // Problem - Array can only add and remove
    // projectFirestore
    //   .collection('products')
    //   .doc(productId)
    //   .update({
    //     liked_by: arrayUnion(user.uid),
    //   });

    // Add information to Firebase collection
    addDocument({
      uid,
      id,
      name,
      price,
      producer,
      description,
      image,
      shop,
      lat,
      lng,
      shopName,
      inStock,
      productType,
      productUrl,
    });

    // Print to check
    console.log(
      'user',
      user.uid,
      id,
      name,
      price,
      producer,
      description,
      image,
      shop,
      lat,
      lng,
      shopName,
      inStock,
      productType,
      productUrl
    );
  };

  const handleUnsave = e => {
    const analytics = getAnalytics();
    logEvent(analytics, 'product_button_unsave');
    e.preventDefault();

    // Change icon state
    setSaved(false);
    console.log('Product saved');

    // Define properties
    const type = 'unsave';
  };

  // Only chnage state when document creation is successful
  useEffect(() => {
    if (response.success) {
      // Change Icon state
      setSaved(true);
    }
  }, [response.success]);

  return (
    <>
      {!saved && user && (
        <Button className="save-button" onClick={handleSave}>
          <FavoriteBorderIcon className="favorite-border-icon" />
        </Button>
      )}
      {saved && user && (
        <Button className="save-button" onClick={handleUnsave}>
          <FavoriteIcon className="favorite-border-icon" />
        </Button>
      )}
      {!saved && !user && (
        <Link to="/signup">
          <Button className="save-button">
            <FavoriteBorderIcon className="favorite-border-icon" />
          </Button>
        </Link>
      )}
    </>
  );
}
