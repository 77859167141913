import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import firebase from 'firebase/compat/app';

// Create dummy geopoint
// const geoloc = new firebase.firestore.GeoPoint(59.95, 30.33);

// Second try
const initialState = {
  product: {
    id: 'XXXXX',
    name: 'default_name',
    price: 1111,
    producer: 'deafult_producer',
    description: 'default_description',
    image: 'default_image',
    shop: 'shops/V6W4TvYDBjFKS50saJQr',
    geoloc: { lat: 59.95, lng: 30.33 },
  },
  // Location for Edinburgh
  lat: 55.9533,
  lng: -3.1883,
};

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  function updateProduct(product) {
    dispatch({
      type: 'UPDATE_PRODUCT',
      payload: product,
    });
  }

  function updateLat(lat) {
    dispatch({
      type: 'UPDATE_LAT',
      payload: lat,
    });
  }

  function updateLng(lng) {
    dispatch({
      type: 'UPDATE_LNG',
      payload: lng,
    });
  }

  // console.log('---', updateLat, updateLng);

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        updateProduct,
        updateLat,
        updateLng,
      }}
      // value={{
      //   product: state.product,
      //   updateProduct,
      //   lat: state.lat,
      //   updateLat,
      //   lng: state.lng,
      //   updateLng,
      // }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
